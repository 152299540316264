.columns-wrapper {
  .header {
    text-transform: uppercase;
    font-weight: 900;
    .text-checkbox {
      text-transform: none;
    }
  }
  .afp-chip {
    text-overflow: ellipsis;
    word-wrap: none;
  }
}
.audienceList {
  height: auto;
  padding-bottom: 1rem;
  max-height: 31.25rem;
  overflow-y: auto;
}
